<template>
  <v-card flat>
    <v-card-title class="text-uppercase">
      {{ $t("events.liveNow") }}
    </v-card-title>
    <v-data-table
      v-if="liveHeats && liveHeats.length > 0"
      :headers="heatsHeaders"
      :items="liveHeats"
      mobile-breakpoint="0"
      disable-sort
      disable-pagination
      hide-default-footer
    >
      <template v-slot:item.name="{ item }">
        <span style="white-space: nowrap">
          {{ item.name }}
        </span>
      </template>

      <template v-slot:item.startTime="{ item }">
        <span style="white-space: nowrap">
          {{ $d(item.startTime, "shortDateTime") }}
        </span>
      </template>

      <template v-slot:item.state="{ item }">
        <EventStateChip :state="item.state" />
      </template>
    </v-data-table>
    <v-card-text v-else>
      {{ $t("events.noHeats") }}
    </v-card-text>

    <v-card-title class="text-uppercase">
      {{ $t("events.finishedHeats") }}
    </v-card-title>
    <v-data-table
      v-if="finishedHeats && finishedHeats.length > 0"
      :headers="heatsHeaders"
      :items="finishedHeats"
      mobile-breakpoint="0"
      disable-sort
      disable-pagination
      hide-default-footer
    >
      <template v-slot:item.name="{ item }">
        <span style="white-space: nowrap">
          {{ item.name }}
        </span>
      </template>

      <template v-slot:item.startTime="{ item }">
        <span style="white-space: nowrap">
          {{ $d(item.startTime, "shortDateTime") }}
        </span>
      </template>

      <template v-slot:item.state="{ item }">
        <EventStateChip :state="item.state" />
      </template>
    </v-data-table>
    <v-card-text v-else>
      {{ $t("events.noHeats") }}
    </v-card-text>

    <v-card-title class="text-uppercase">
      {{ $t("events.scheduledHeats") }}
    </v-card-title>
    <v-data-table
      v-if="scheduledHeats && scheduledHeats.length > 0"
      :headers="heatsHeaders"
      :items="scheduledHeats"
      mobile-breakpoint="0"
      disable-sort
      disable-pagination
      hide-default-footer
    >
      <template v-slot:item.name="{ item }">
        <span style="white-space: nowrap">
          {{ item.name }}
        </span>
      </template>

      <template v-slot:item.startTime="{ item }">
        <span style="white-space: nowrap">
          {{ $d(item.startTime, "shortDateTime") }}
        </span>
      </template>

      <template v-slot:item.state="{ item }">
        <EventStateChip :state="item.state" />
      </template>
    </v-data-table>
    <v-card-text v-else>
      {{ $t("events.noHeats") }}
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import EventStateChip from "@/components/EventStateChip";

export default {
  name: "TabOverview",
  components: {
    EventStateChip
  },
  computed: {
    ...mapState({
      heats: state => state.events.selectedItem.heats
    }),
    heatsHeaders() {
      return [
        {
          text: this.$i18n.t("events.table.name"),
          value: "name"
        },
        {
          text: this.$i18n.t("events.table.time"),
          value: "startTime"
        },
        {
          text: this.$i18n.t("events.table.competitors"),
          value: "competitors.length"
        },
        {
          text: this.$i18n.t("events.table.status"),
          value: "state"
        }
      ];
    },
    liveHeats() {
      return this.heats.filter(heat => heat.state === "LIVE");
    },
    finishedHeats() {
      return this.heats.filter(heat => heat.state === "FINISHED");
    },
    scheduledHeats() {
      return this.heats.filter(heat => heat.state === "SCHEDULED");
    }
  }
};
</script>
<style scoped>
.v-data-table /deep/ th[role="columnheader"] {
  white-space: nowrap;
}
</style>
